import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ lang, meta }) {
  const {
    wpgraphql: {
      page: { seo },
    },
  } = useStaticQuery(
    graphql`
      query querySEO {
        wpgraphql {
          page(id: "/strona-glowna/", idType: URI) {
            seo {
              metaDesc
              opengraphDescription
              opengraphTitle
              opengraphSiteName
              opengraphImage {
                sourceUrl
              }
            }
          }
        }
      }
    `
  );

  const ogImage = seo.opengraphImage
    ? { property: `og:image`, content: seo.opengraphImage.sourceUrl }
    : {};

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={seo.opengraphTitle}
      script={[
        {
          type: 'text/javascript',
          innerHTML: `var _mtm = window._mtm = window._mtm || [];
_mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
g.async=true; g.src='https://user.aitnetapp.com/js/container_LO0NSFJK.js'; s.parentNode.insertBefore(g,s);`,
        },
        {
          type: 'text/javascript',
          innerHTML: `var _paq = window._paq = window._paq || [];_paq.push(['trackPageView']); _paq.push(['enableLinkTracking']); (function() { var u="https://user.aitnetapp.com/"; _paq.push(['setTrackerUrl', u+'matomo.php']); _paq.push(['setSiteId', '11']); var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0]; g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s); })();`,
        },
      ]}
      meta={[
        {
          name: `robots`,
          content: `index, follow`,
        },
        {
          name: `description`,
          content: seo.metaDesc || seo.opengraphDescription,
        },
        {
          property: `og:title`,
          content: seo.opengraphTitle,
        },
        {
          property: `og:description`,
          content: seo.opengraphDescription || seo.metaDesc,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: seo.opengraphTitle,
        },
        {
          name: `twitter:description`,
          content: seo.metaDesc || seo.opengraphDescription,
        },
                {
          name: `google-site-verification`,
          content: "073tZj6h6ySlwB4A_uiAKSLF_IGiPjJbDx9VXnfSi5g",
        },
        ogImage,
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `pl`,
  meta: [],
};

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
};

export default SEO;
