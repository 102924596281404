/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Button = styled.button`
  position: relative;
  display: block;
  padding: 16px 40px;
  background: #47baea;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.subFont};
  font-weight: ${({ theme }) => theme.bold};
  font-size: 1.2rem;
  width: 100%;
  margin: 0;
  max-width: 150px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s;
  text-align: center;
  box-shadow: 0 1px 10px 2px rgba(0, 0, 0, 0.1);
  letter-spacing: 1px;
  border-radius: 50px;
  @media (hover: hover) {
    &:hover {
      background: #7db2d7;
      color: ${({ theme }) => theme.colors.white};
    }
  }
  ${({ secondary }) =>
    secondary &&
    css`
      background: #ee5126;
      border-radius: 0;
      height: 50px;
      padding: 16px 50px;
      &:before,
      &:after {
        content: '';
        position: absolute;
      }
      &:before {
        width: 0;
        height: 0;
        left: 0;
        top: 0;
        border-top: 25px solid transparent;
        border-bottom: 25px solid transparent;
        border-left: 35px solid #fff;
      }
      &:after {
        width: 0;
        height: 0;
        top: 0;
        right: 0;
        border-top: 25px solid transparent;
        border-bottom: 25px solid transparent;
        border-right: 35px solid #fff;
      }
    `}
  ${({ submit }) =>
    submit &&
    css`
      position: relative;
      height: 50px;
      padding: 16px;
      border: none;
      background: ${({ theme }) => theme.colors.green};
      color: ${({ theme }) => theme.colors.white};
      &:before {
        content: '';
        position: absolute;
        top: 27%;
        left: 45%;
        width: 24px;
        height: 24px;
        border: 3px solid ${({ theme }) => theme.white};
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-radius: 50%;
        background: none;
        opacity: ${({ disabled }) => (disabled ? '1' : '0')};
        animation: ${spin} 1s ease infinite;
      }
      &:after {
        display: none;
      }
    `}
`;

export default Button;

const ViewButtonWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 20px;
  width: 100%;
  max-width: 190px;
  height: 66px;
  border-radius: 30px;
  transition: 0.3s;
  background: transparent;
  cursor: pointer;
  ${({ theme }) => theme.mq.xs} {
    margin-left: 5px;
  }
  ${({ active }) =>
    active &&
    css`
      background: ${({ theme }) => theme.colors.lightBlue};
    `}
  &:hover {
    background: ${({ theme, active }) =>
      active ? theme.colors.lightBlue : `#F7F7F7`};
  }
  svg {
    fill: ${({ theme }) => theme.colors.gray};
    transition: 0.3s;
  }
  span {
    font-size: 1.4rem;
    white-space: nowrap;
    margin-left: 9px;
    ${({ theme }) => theme.mq.s} {
      margin-left: 12px;
      font-size: 1.5rem;
    }
  }
  .d {
    fill: ${({ theme, active }) =>
      active ? theme.colors.lightGreen100 : '#888'};
    transition: 0.3s;
  }
  .a,
  .c {
    fill: none;
  }
  .a {
    stroke: ${({ theme, active }) =>
      active ? theme.colors.lightGreen100 : '#888'};
    transition: 0.3s;
    stroke-width: 2px;
  }
  .b {
    stroke: none;
  }
`;

export const ViewButton = ({ children, list, ...props }) => (
  <ViewButtonWrapper {...props}>
    {list ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="18"
        viewBox="0 0 35 22"
      >
        <rect className="d" width="4" height="4" />
        <rect className="d" width="4" height="4" transform="translate(0 9)" />
        <rect className="d" width="4" height="4" transform="translate(0 18)" />
        <rect className="d" width="27" height="4" transform="translate(8)" />
        <rect className="d" width="27" height="4" transform="translate(8 9)" />
        <rect className="d" width="27" height="4" transform="translate(8 18)" />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="29"
        viewBox="0 0 35 34"
      >
        <g className="a">
          <rect className="b" width="15" height="15" />
          <rect className="c" x="1" y="1" width="13" height="13" />
        </g>
        <g className="a" transform="translate(0 19)">
          <rect className="b" width="15" height="15" />
          <rect className="c" x="1" y="1" width="13" height="13" />
        </g>
        <g className="a" transform="translate(20)">
          <rect className="b" width="15" height="15" />
          <rect className="c" x="1" y="1" width="13" height="13" />
        </g>
        <g className="a" transform="translate(20 19)">
          <rect className="b" width="15" height="15" />
          <rect className="c" x="1" y="1" width="13" height="13" />
        </g>
      </svg>
    )}

    <span>{children}</span>
  </ViewButtonWrapper>
);
